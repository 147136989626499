<template>
  <div class="page-container">
    <!-- Section Calculate It Start -->
    <div class="section-wrapper">
      <div class="content-wrapper">
        <Content1
          :title="$t('calculate.title')"
          :description="$t('calculate.description')"
        ></Content1>
      </div>
    </div>
    <!-- Section Calculate It End -->

    <!-- Section Calculator Start -->
    <div class="section-wrapper">
      <div class="content-wrapper">
        <v-row no-gutters>
          <v-col cols="6" class="pr-4">
            <v-card
              tile
              min-height="440"
              elevation="0"
              color="greyCard"
              class="pa-10"
            >
              <v-form ref="calculatorForm">
                <div class="mt-4">
                  <label class="text-body-1 text-capitalize">{{
                    $t('text.propertyPurchasePrice')
                  }}</label>
                  <v-text-field
                    v-model="propertyPurchasePrice"
                    outlined
                    hide-details
                    :label="$t('text.amount')"
                    class="mt-3 background"
                  ></v-text-field>
                </div>
                <div class="mt-6">
                  <label class="text-body-1 text-capitalize">{{
                    $t('text.loanAmount')
                  }}</label>
                  <v-text-field
                    v-model="loadAmount"
                    outlined
                    hide-details
                    :label="$t('text.amount')"
                    class="mt-3 background"
                  ></v-text-field>
                </div>
                <div class="mt-6 d-flex">
                  <v-btn
                    outlined
                    tile
                    min-width="145"
                    min-height="42"
                    class="text-capitalize text-body-2 title--text font-weight-bold"
                    @click="resetForm"
                    >{{ $t('text.reset') }}</v-btn
                  >
                  <v-btn
                    depressed
                    tile
                    color="primary"
                    min-width="145"
                    min-height="42"
                    class="text-capitalize text-body-2 ml-4 font-weight-bold"
                    >{{ $t('text.calculate') }}</v-btn
                  >
                </div>
              </v-form>
            </v-card>
          </v-col>
          <v-col cols="6" class="pl-4">
            <v-card tile elevation="0" color="transparent" class="pa-10">
              <div>
                <v-card-title class="px-0 font-weight-bold">{{
                  $t('text.saleAndPurchaseAgreement')
                }}</v-card-title>
                <div class="text-body-1 label--text text-capitalize">
                  {{ $t('text.legalFee') }} :
                  <span class="primary--text text-h6 font-weight-regular"
                    >RM0.00</span
                  >
                </div>
                <div class="text-body-1 label--text mt-2 text-capitalize">
                  {{ $t('text.stampDuty') }}* :
                  <span class="primary--text text-h6 font-weight-regular"
                    >RM0.00</span
                  >
                </div>
              </div>
              <div class="mt-6">
                <v-card-title class="px-0 font-weight-bold">{{
                  $t('text.loanAgreement')
                }}</v-card-title>
                <div class="text-body-1 label--text text-capitalize">
                  {{ $t('text.legalFee') }} :
                  <span class="primary--text text-h6 font-weight-regular"
                    >RM0.00</span
                  >
                </div>
                <div class="text-body-1 label--text mt-2 text-capitalize">
                  {{ $t('text.stampDuty') }}* :
                  <span class="primary--text text-h6 font-weight-regular"
                    >RM0.00</span
                  >
                </div>
              </div>
              <p class="label--text mt-10">
                *{{ $t('message.calculatorMessage') }}
              </p>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- Section Calculator End -->

    <!-- Section Contact Us Now Start -->
    <ContactUsNow></ContactUsNow>
    <!-- Section Contact Us Now End -->
  </div>
</template>

<script>
import ContactUsNow from '@/components/section/ContactUsNow'

export default {
  name: 'Calculator',
  components: {
    ContactUsNow
  },
  data: () => ({
    propertyPurchasePrice: null,
    loadAmount: null
  }),
  methods: {
    resetForm() {
      this.$refs.calculatorForm.reset()
    }
  }
}
</script>

<style></style>
